<!--
 * @Descripttion: 新保
 * @version: 1.0
 * @Author: 魏鑫
 * @Date: 2019-12-24 14:38:31
 * @LastEditors: 张天鹏
 * @LastEditTime: 2020-06-05 10:24:28
 -->
<template>
    <div>
      <div class="mainbody" v-if="!isPopupShow">
        <div class="search clearfix">
         <div class="searchlf">
           <md-field>
            <md-input-item
                ref="input"
                v-model="Tbname"
                placeholder="请输入投保人/被保人姓名"
                is-amount
                :maxlength="10"
              ></md-input-item>
          </md-field>
         </div>
         <div class="searchfr">
           <md-field>
              <md-button icon="search" @click="search"></md-button>
           </md-field>
         </div>
         <div class="searchfr">
           <md-field>
              <md-button icon="refresh" @click="cleardata"></md-button>
           </md-field>
         </div>
      </div>
      <div class="list" v-if="reportList.length">
         <md-scroll-view ref="scrollView"
                        auto-reflow
                        :scrolling-x="false"
                        @end-reached="loadMore">
          <div class="product" v-for="(item,index) of reportList" :key="index" @click="xbShow(item.spolicyno)" >
            <div class="product-lf">
              <div class="product-rg-top">
                  <p class="xb-classname">{{item.classname}}</p>
                  <span class="xb-state">{{item.polistInfo?item.polistInfo.label:''}}</span>
              </div>
              <div class="product-bom">
                <div class="product-rg-bom">
                    <span class="xb-policy">保单号：{{item.policyno}}</span>
                </div>
                <div class="product-rg-bom">
                  <div class="tb-rq">
                    <span >投保日期：{{item.appdate}}</span>
                  </div>
                  <div class="sx-rq">
                    <span v-if="isNaN(item.sxdate)">生效日期：{{item.sxdate}}</span>
                  </div>
                </div>
                <div class="product-rg-bom">
                  <div class="policy-t"><span >投保人：{{item.tbname}}</span></div>
                  <div class="policy-b"><span >被保人：{{item.bbname}}</span></div>
                </div>
                <div class="product-rg-bom">
                  <div class="a-m"><span >年保费：{{item.mount}}</span></div>
                  <div class="m-t"><span >保额：{{item.amount}}</span></div>
                </div>
                <div class="product-rg-bom" v-if="item.empno !== item.csrno">
                    <span class="xb-emp">继承业务员：{{item.csrname}}</span>
                </div>
              </div>
            </div>
        </div>
          <div class="heightbom"></div>
                    <md-scroll-view-more slot="more" :is-finished="loading" />
        </md-scroll-view>
      </div>
            <md-result-page :img-url="require('@/assets/abd/image/null.png')"
                      v-else
                      subtext="要不然刷新试试？" />
      </div>
      <md-popup v-model="isPopupShow" :mask-closable="closable">
          <div class="icdoling">
              <md-icon name="spinner" color="blue" size="lg" style="-webkit-filter:invert(1)"></md-icon>
          </div>
        </md-popup>
    </div>
</template>
<script>
import { Selector, Field, FieldItem } from 'mand-mobile'
import { xbList } from '@/api/hx/newpolicy/index'
import { getStorage } from '@/lib/util'
import loadMore from '@/mixins/loadmore'
export default {
  mixins: [loadMore],
  components: {
    [Selector.name]: Selector,
    [Field.name]: Field,
    [FieldItem.name]: FieldItem
  },
  data () {
    return {
      closable: false,
      isPopupShow: true,
      Tbname: '',
      personNum: 1,
      ossurl: '',
      pageNo: 1,
      pageSize: 6,
      loading: false,
      reportList: [],
      totalpage: 0,
      isSelectorShow: false,
      shortName: []
    }
  },
  created () {
    this.ossurl = getStorage('ossurl', '')
    this.height = document.body.clientHeight
    this.getData()
  },
  mounted () {
    document.querySelector('.list').style.height = `${this.height}px`
    document.querySelector('.list').style.width = `${this.width}px`
  },
  methods: {
    async getData (isInit = true) {
      this.isPopupShow = true
      let { pageNo, pageSize } = this
      if (!isInit) {
        // this.totalpage < pageNo || this.totalpage == pageNo ? this.pageNo = this.totalpage : this.pageNo = pageNo + 1
        this.pageNo++
        this.$refs.scrollView.finishLoadMore()
      }
      let res = await xbList({
        page: this.pageNo, size: pageSize, Tbname: this.Tbname
      })
      if (this.pageNo > 1) {
        for (let item in res.data.data.rows) {
          this.reportList.push(res.data.data.rows[item])
          this.total = res.data.data.total
          this.totalPage = res.data.data.totalpage
        }
      } else {
        this.reportList = res.data.data.rows
        this.total = res.data.data.total
        this.totalPage = res.data.data.totalpage
      }
      this.isPopupShow = false
    },
    xbShow (spolicyno) { // 跳转填写信息
      this.$router.push({
        path: '/xbShow',
        query: { spolicyno: spolicyno }
      })
    },
    search () {
      this.getData(this)
    },
    cleardata () {
      this.Tbname = ''
      this.personNum = 1
      this.ossurl = ''
      this.pageNo = 1
      this.pageSize = 6
      this.loading = false
      this.reportList = []
      this.totalpage = 0
      this.isSelectorShow = false
      this.shortName = []
      this.getData(this)
    }
  }
}
</script>
<style lang="stylus" scoped>
.heightbom{
  height:150px;
}
/deep/ .icdoling .md-icon {
          width: 1.42rem;
          height: 1.42rem;
          line-height: 1.42rem;
      }
  .mainbody{
    background-color  #F7F6FB
    width 100vw
    height 100vh
    overflow scroll
  }
  .product{
    height 520px;
    width 960px;
    background  #fff
    border-top 20px solid #F7F6FB
    margin: 0px auto
  }
  .product-lf{
    padding 35px 0
    width 100%
    height 100%
    left 50px
    position relative
    .product-bom{
      padding 35px 0
    }
  }
  .product-rg-top{
    display flex
    align-items center
  }
  .product-rg-top span,p{
    height 70px
    line-height 70px
    font-family 'PingFang-SC-Medium'
  }
  .xb-state{
    height 50px!important
    line-height 50px!important
    font-size 30px!important
    color #B88E5C
    position absolute
    right 100px
    background-color #FFF8ED
    width 100px
    text-align center
  }
  .product-rg-bom{
    display flex
    justify-content flex-start
    text-align:left
  }
  .product-rg-bom span{
    line-height 70px
    font-size 0.35rem
    color #9D9D9D
    font-family 'PingFang-SC-Medium'
  }
  .search{
    width 100vw
    line-height 10vh
    display flex
    align-items center
  }
  .search .searchlf{
    // float left
    width 80%
    height 100%
  }
  .searchlf
  /deep/ .md-field-item-placeholder{
    font-size 0.3rem
  }
  .searchfr{
    // float left
    width 10%
    // height 100%
  }
  .searchfr .md-field{
    // padding 0.5rem 0rem
  }
  .list{
    height 100%
  }
  .clearfix:after,.clearfix:before{
      content ""
      display table
  }
  .clearfix:after{
    clear both
  }
  .clearfix{
    *zoom 1
  }
  .xb-policy{

  }
  .tb-rq{
    width 50%
  }
  .xb-classname{
    font-size 45px
    font-weight:bold
    display inline-block
    width  7rem!important
    overflow: hidden!important
    text-overflow:ellipsis!important
    white-space: nowrap!important
  }
  .sx-rq{

  }
  .policy-t{
    width 50%
  }
   .policy-b{
  }
  .a-m{
    width 50%
  }
  .m-t{
  }
  .xb-emp{

  }
</style>
